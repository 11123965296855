import { DetailsWrapper, FooterWrapper, HeaderWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const OrderCreatedLayout = props => {
  const {
    header,
    subHeader,
    details,
    footer,
    loader
  } = props;
  return _jsxs(Wrapper, {
    children: [header && _jsx(HeaderWrapper, {
      children: header
    }), subHeader && _jsx("div", {
      children: subHeader
    }), loader && _jsx("div", {
      children: loader
    }), details && _jsx(DetailsWrapper, {
      children: details
    }), footer && _jsx(FooterWrapper, {
      children: footer
    })]
  });
};