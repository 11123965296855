import { List, ListItem, Text } from '../../components/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutCspWhenSubmitInfo = () => {
  return _jsx(Text, {
    children: _jsx(List, {
      children: _jsx(ListItem, {
        children: "\u0417\u0430\u044F\u0432\u043B\u0435\u043D\u0438\u0435 \u043D\u0430 \u043B\u044C\u0433\u043E\u0442\u043D\u044B\u0435 \u0441\u0430\u043D\u0430\u0442\u043E\u0440\u043D\u043E-\u043A\u0443\u0440\u043E\u0440\u0442\u043D\u044B\u0435 \u043F\u0443\u0442\u0435\u0432\u043A\u0438 \u043D\u0430 \u043E\u0431\u044A\u0435\u043A\u0442\u044B \u0440\u0430\u0437\u0434\u0435\u043B\u0430 \xAB________\xBB \u043D\u0430 \u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0433\u043E\u0434 \u0444\u043E\u0440\u043C\u0438\u0440\u0443\u044E\u0442\u0441\u044F \u0435\u0436\u0435\u0433\u043E\u0434\u043D\u043E \u0432 \u0430\u0432\u0433\u0443\u0441\u0442\u0435-\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u0435. \u0417\u0430\u044F\u0432\u043A\u0443 \u043C\u043E\u0436\u043D\u043E \u043F\u043E\u0434\u0430\u0442\u044C \u043D\u0430\u043F\u0438\u0441\u0430\u0432 \u0437\u0430\u044F\u0432\u043B\u0435\u043D\u0438\u0435 \u0432 \u043A\u043E\u043C\u0438\u0441\u0441\u0438\u044E \u043F\u043E \u0440\u0430\u0441\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u0438\u044E \u043F\u0443\u0442\u0451\u0432\u043E\u043A."
      })
    })
  });
};
export default AboutCspWhenSubmitInfo;