import { svgIconClasses } from '@mui/material';
const snackbars = baseTheme => ({
  MuiSnackbar: {
    styleOverrides: {
      root: {
        position: 'relative',
        left: 'unset',
        right: 'unset',
        bottom: 'unset !important'
      }
    }
  },
  MuiSnackbarContent: {
    defaultProps: {
      color: 'info'
    },
    styleOverrides: {
      root: _ref => {
        let {
          ownerState
        } = _ref;
        const styles = {
          width: '100%',
          position: 'relative',
          padding: baseTheme.spacing(2),
          backgroundColor: baseTheme.palette.common.black,
          boxShadow: 'none',
          [baseTheme.breakpoints.up('sm')]: {
            width: '448px'
          },
          [baseTheme.breakpoints.up('md')]: {
            width: '544px'
          }
        };
        switch (ownerState.color) {
          case 'success':
            {
              styles.border = `1px solid ${baseTheme.palette.success.main}`;
              break;
            }
          case 'warning':
            {
              styles.border = `1px solid ${baseTheme.palette.warning.main}`;
              break;
            }
          case 'error':
            {
              styles.border = `1px solid ${baseTheme.palette.warning.main}`;
              break;
            }
          default:
            {
              styles.border = `1px solid transparent`;
              break;
            }
        }
        return styles;
      },
      message: _ref2 => {
        let {
          ownerState
        } = _ref2;
        const styles = {
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          padding: baseTheme.spacing(0, 4, 0, 0),
          [`.${svgIconClasses.root}`]: {
            marginRight: baseTheme.spacing()
          },
          whiteSpace: 'pre-line',
          wordBreak: 'break-word'
        };
        switch (ownerState.color) {
          case 'success':
            {
              styles[`.${svgIconClasses.root}`] = {
                ...(styles[`.${svgIconClasses.root}`] ?? {}),
                color: baseTheme.palette.success.main
              };
              break;
            }
          case 'warning':
            {
              styles[`.${svgIconClasses.root}`] = {
                ...(styles[`.${svgIconClasses.root}`] ?? {}),
                color: baseTheme.palette.warning.main
              };
              break;
            }
          case 'error':
            {
              styles[`.${svgIconClasses.root}`] = {
                ...(styles[`.${svgIconClasses.root}`] ?? {}),
                color: baseTheme.palette.warning.main
              };
              break;
            }
          default:
            {
              styles[`.${svgIconClasses.root}`] = {
                ...(styles[`.${svgIconClasses.root}`] ?? {}),
                color: baseTheme.palette.common.white
              };
              break;
            }
        }
        return styles;
      },
      action: {
        position: 'absolute',
        right: baseTheme.spacing(2),
        top: baseTheme.spacing()
      }
    }
  }
});
export default snackbars;