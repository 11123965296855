import { createSelector } from '@reduxjs/toolkit';
import { userApi } from 'api/user';
import { ESocialPackageStatus } from 'domain/model';
import { socialPackagesStableSortPredicate } from 'features/socialPackage/utils';
export const socialPackagesSelector = store => {
  const {
    data: value
  } = userApi.endpoints.getSocialPackagesList.select()(store);
  return value ?? null;
};
export const socialPackagesSortedSelector = createSelector(socialPackagesSelector, sp => {
  if (!sp) {
    return null;
  }
  return [...sp].sort(socialPackagesStableSortPredicate);
});
export const socialPackagesAgreedSelector = createSelector(socialPackagesSortedSelector, sp => {
  if (!sp) {
    return null;
  }
  return sp.filter(spItem => [ESocialPackageStatus.Active, ESocialPackageStatus.Upcoming].some(s => s === spItem.status));
});
export const socialPackagesToUserAttentionSelector = createSelector(socialPackagesSortedSelector, sp => {
  if (!sp) {
    return null;
  }
  return sp.filter(spItem => [ESocialPackageStatus.Ready, ESocialPackageStatus.Active, ESocialPackageStatus.Upcoming].some(s => s === spItem.status) && !spItem.employeeAgreement);
});

// Получение
export const socialPackagesFirstActiveSelector = createSelector(socialPackagesSortedSelector, sp => {
  if (!sp) {
    return null;
  }
  const statuses = [ESocialPackageStatus.Active, ESocialPackageStatus.Upcoming, ESocialPackageStatus.Ready];
  for (const status of statuses) {
    const found = sp.find(obj => obj.status === status);
    if (found) {
      return found;
    }
  }
  return null;
});
export const socialPackagesFirstReadySelector = createSelector(socialPackagesSortedSelector, sp => {
  return sp?.find(spItem => spItem.status === ESocialPackageStatus.Ready) ?? null;
});