import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { EDateFormat } from 'domain/model';
import { cspOfferTag } from 'features/offer/booking/utils';
import OfferTag from 'features/offer/components/tag';
import moment from 'moment-timezone';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const PrivilegeListText = _ref => {
  let {
    endDate
  } = _ref;
  const formattedEndDate = moment(endDate).format(EDateFormat.Human);
  return _jsxs(_Fragment, {
    children: [_jsx(Typography, {
      variant: "subtitle1",
      children: "\u0412\u044B\u0431\u043E\u0440 \u0438\u0437\xA0\u043F\u0440\u043E\u0432\u0435\u0440\u0435\u043D\u043D\u044B\u0445 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0439"
    }), _jsx(Splitter, {
      size: 0.5
    }), _jsxs(Typography, {
      component: "div",
      variant: "body2",
      children: ["\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u0443\u0441\u043B\u0443\u0433\u0438 \u043E\u0431\u044A\u0435\u043A\u0442\u043E\u0432 \u043E\u0442\u0434\u044B\u0445\u0430, \u043E\u0442\u043C\u0435\u0447\u0435\u043D\u043D\u044B\u0445 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u043C \u0437\u043D\u0430\u043A\u043E\u043C", ' ', _jsx(OfferTag, {
        offerTag: cspOfferTag,
        size: "micro"
      }), ' ', "\u043D\u0430 \u0412\u0438\u0442\u0440\u0438\u043D\u0435."]
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(Typography, {
      variant: "subtitle1",
      children: "\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u044F\xA0\u2014 \u043F\u043E\u0441\u043B\u0435 \u043E\u0442\u0434\u044B\u0445\u0430"
    }), _jsx(Splitter, {
      size: 0.5
    }), _jsxs(Typography, {
      variant: "body2",
      children: ["\u0412\u044B\xA0\u0432\u044B\u0431\u0438\u0440\u0430\u0435\u0442\u0435 \u043C\u0435\u0441\u0442\u043E \u0438\xA0\u0434\u0430\u0442\u044B \u0438\xA0\u043E\u043F\u043B\u0430\u0447\u0438\u0432\u0430\u0435\u0442\u0435 \u043F\u0443\u0442\u0451\u0432\u043A\u0443 \u0441\u0430\u043C\u043E\u0441\u0442\u043E\u044F\u0442\u0435\u043B\u044C\u043D\u043E. \u041F\u043E\u0441\u043B\u0435 \u043E\u0442\u0434\u044B\u0445\u0430 \u043F\u043E\u0434\u0430\u0435\u0442\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B \u043D\u0430\xA0\u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u044E \u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u043E\u043C\u0443 \u0437\u0430\xA0\u043F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u041A\u0421\u041F \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u0443 \u043F\u043E\xA0\u0443\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044E \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u043E\u043C \u0432\xA0\u0432\u0430\u0448\u0435\u043C \u043F\u043E\u0434\u0440\u0430\u0437\u0434\u0435\u043B\u0435\u043D\u0438\u0438.", ' ']
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsxs(Typography, {
      variant: "body2",
      children: ["\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u044E \u0432\u044B\xA0\u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C", ' ', _jsx(Typography, {
        component: "span",
        variant: "subtitle2",
        children: "\u0432\xA0\u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u043C\u0435\u0441\u044F\u0446\u0430"
      }), ' ', "\u043F\u043E\u0441\u043B\u0435 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u0443\u0441\u043B\u0443\u0433\u0438, \u043D\u043E\xA0", _jsxs(Typography, {
        component: "span",
        variant: "subtitle2",
        children: ["\u043D\u0435\xA0\u043F\u043E\u0437\u0434\u043D\u0435\u0435 ", formattedEndDate]
      }), "."]
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(Typography, {
      variant: "subtitle1",
      children: "\u0414\u0440\u0443\u0433\u0438\u0435 \u043B\u044C\u0433\u043E\u0442\u044B"
    }), _jsx(Splitter, {
      size: 0.5
    }), _jsx(Typography, {
      variant: "body2",
      children: "\u0415\u0441\u043B\u0438 \u0443\xA0\u0432\u0430\u0441 \u0435\u0441\u0442\u044C \u043F\u0440\u0430\u0432\u043E \u043D\u0430\xA0\u0433\u043E\u0441\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u0432\xA0\u0441\u0432\u044F\u0437\u0438 \u0441\xA0\u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044C\u043D\u044B\u043C \u043F\u043E\u043B\u043E\u0436\u0435\u043D\u0438\u0435\u043C, \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u0438 \u043E\u0442\u0434\u044B\u0445\u0430 \u043C\u043E\u0436\u0435\u0442 \u043F\u0440\u0438\u0432\u0435\u0441\u0442\u0438 \u043A\xA0\u0443\u0442\u0440\u0430\u0442\u0435 \u0433\u043E\u0441\u043B\u044C\u0433\u043E\u0442\u044B."
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(Typography, {
      variant: "body2",
      children: "\u0415\u0441\u043B\u0438 \u0432\u044B\xA0\u043E\u0444\u043E\u0440\u043C\u043B\u044F\u0435\u0442\u0435 \u0431\u043E\u043B\u0435\u0435 \u043E\u0434\u043D\u043E\u0439 \u043F\u0443\u0442\u0451\u0432\u043A\u0438 \u0432\xA0\u0433\u043E\u0434, \u0442\u043E\xA0\u0437\u0430\xA0\u0432\u0442\u043E\u0440\u0443\u044E \u0438\xA0\u043F\u043E\u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0443\u0434\u0435\u0440\u0436\u0430\u043D \u043D\u0430\u043B\u043E\u0433 \u043D\u0430\xA0\u0434\u043E\u0445\u043E\u0434\u044B \u0444\u0438\u0437\u043B\u0438\u0446 \u043E\u0442\xA0\u0441\u0443\u043C\u043C\u044B \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u0438."
    })]
  });
};
export default PrivilegeListText;