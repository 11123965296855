import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { SocialPackageRefuseActionsAdapter, SocialPackageRefuseContentAdapter, SocialPackageRefuseTitleAdapter } from './adapters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * отказ от КСП
 */

export const SocialPackageRefuseEventContainer = props => {
  return _jsx(DialogueEventContentBuilderLayout, {
    title: _jsx(SocialPackageRefuseTitleAdapter, {}),
    actions: _jsx(SocialPackageRefuseActionsAdapter, {
      ...props
    }),
    children: _jsx(SocialPackageRefuseContentAdapter, {})
  });
};