import { useRefuseSocialPackageMutation } from 'api/user';
import ContentLoader from 'components/common/loader';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SocialPackageRefuseActionsAdapter = props => {
  const {
    payload: {
      socialPackage: {
        id
      }
    },
    onNext
  } = props;
  const [onRefuse, {
    isLoading
  }] = useRefuseSocialPackageMutation();
  const onRefuseInternal = async () => {
    await onRefuse({
      socialPackageId: id
    });
    onNext();
  };
  return _jsxs(ConfirmGroupButtons, {
    children: [_jsx(MPButton, {
      color: "brand",
      fullWidth: true,
      size: 'large',
      onClick: onNext,
      disabled: isLoading,
      children: "\u0415\u0449\u0451 \u043F\u043E\u0434\u0443\u043C\u0430\u044E"
    }), _jsxs(MPButton, {
      color: "brand",
      variant: "text",
      size: 'large',
      fullWidth: true,
      disabled: isLoading,
      onClick: onRefuseInternal,
      children: ["\u041E\u0442\u043A\u0430\u0437\u0430\u0442\u044C\u0441\u044F \u043E\u0442 \u043B\u044C\u0433\u043E\u0442\u044B", isLoading && _jsx(ContentLoader, {})]
    })]
  });
};