import { Typography } from '@mui/material';
import { EBalanceType } from 'domain/model/enums';
import { getCspRubCurrencyLabel } from 'features/user/utils';
import { PaymentIcon } from 'presentation/components/common/paymentIcon';
import { toCurrency, toRubCurrency } from 'presentation/utils/currency';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserBalanceValue = _ref => {
  let {
    value,
    type,
    hideIcon,
    onClick,
    disabled = false,
    ...others
  } = _ref;
  let func = toCurrency;
  switch (type) {
    case EBalanceType.Bonuses:
      func = toCurrency;
      break;
    case EBalanceType.ASP:
      func = toRubCurrency;
      break;
    case EBalanceType.CSP:
      func = getCspRubCurrencyLabel;
      break;
  }
  let iconSize;
  switch (others.variant) {
    case 'h2':
      iconSize = 'medium';
      break;
    default:
      iconSize = 'small';
      break;
  }
  return value !== null && _jsxs(Wrapper, {
    onClick: onClick,
    children: [!hideIcon && _jsx(PaymentIcon, {
      color: disabled ? 'secondary' : 'inherit',
      type: type,
      fontSize: iconSize
    }), _jsx(Typography, {
      variant: "body2",
      color: disabled ? 'secondary' : 'inherit',
      component: "div",
      ...others,
      children: func(value)
    })]
  });
};