import Splitter from 'components/common/splitter';
import PrivilegeListHowToGetText from '../../components/text/howToGetListText';
import CompensationRest from '../compensatonRest';
import CompensationRulesDoc from '../rulesDoc';
import SummaryText from '../../components/text/summaryText';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SocialPackageHowToUseContent = props => {
  const {
    limitToSelfRest,
    limitToFamilyRest,
    endDate
  } = props;
  return _jsxs(_Fragment, {
    children: [_jsx(SummaryText, {
      endDate: endDate
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(CompensationRest, {
      limitToFamilyRest: limitToFamilyRest,
      limitToSelfRest: limitToSelfRest
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(PrivilegeListHowToGetText, {
      endDate: endDate
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(CompensationRulesDoc, {})]
  });
};
export default SocialPackageHowToUseContent;