import { Link, Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import Splitter from 'presentation/components/common/splitter';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { useEffect, useState } from 'react';
import { convertSecondsToHumanView } from '../../../../utils/date';
import { CodeInput, InfoText } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const CodeConfirmationDialog = props => {
  const {
    open,
    isFetching,
    email,
    validation,
    codeTtl,
    codeRequestId,
    codeNextAttemptSeconds,
    back,
    onGetNewCode,
    onClose,
    onSendCode
  } = props;
  const [timer, setTimer] = useState(codeNextAttemptSeconds ?? 0);
  const [value, setValue] = useState('');
  useEffect(() => {
    if (codeNextAttemptSeconds) {
      setTimer(codeNextAttemptSeconds);
    }
  }, [codeNextAttemptSeconds, codeRequestId]);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimer(time => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [codeNextAttemptSeconds, codeRequestId]);
  return _jsxs(MPConfirmDialog, {
    title: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443\u044E \u043F\u043E\u0447\u0442\u0443",
    open: open,
    maxWidth: "xs",
    fullScreenBreakpoint: "xs",
    closeToBackButtonInFullScreen: false,
    onClose: onClose,
    buttons: _jsxs(MPButton, {
      fullWidth: true,
      color: "brand",
      size: "large",
      disabled: isFetching,
      onClick: () => onSendCode(value),
      children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C", isFetching && _jsx(ContentLoader, {})]
    }),
    children: [_jsxs(InfoText, {
      variant: "body2",
      children: ["\u041D\u0430 \u0412\u0430\u0448\u0443 \u043F\u043E\u0447\u0442\u0443", ' ', _jsx(Typography, {
        variant: "subtitle1",
        component: "span",
        children: email
      }), ' ', "\u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D \u043A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F. \u0415\u0441\u043B\u0438 \u0412\u044B \u043D\u0435 \u043D\u0430\u0448\u043B\u0438 \u043F\u0438\u0441\u044C\u043C\u043E, \u043F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u043F\u0430\u043F\u043A\u0443 \xAB\u0421\u043F\u0430\u043C\xBB. \u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C \u043F\u043E\u0447\u0442\u0443 \u043D\u0443\u0436\u043D\u043E \u0442\u043E\u043B\u044C\u043A\u043E \u043E\u0434\u0438\u043D \u0440\u0430\u0437"]
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(CodeInput, {
      label: "\u041A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F",
      value: value,
      type: "number",
      error: !!validation?.hasError,
      helperText: validation?.message,
      disabled: isFetching,
      onChange: event => setValue(event.target.value)
    }), _jsx(Splitter, {
      size: 2
    }), timer && timer && timer >= 0 ? _jsxs(_Fragment, {
      children: [codeTtl && _jsxs(Typography, {
        variant: "body2",
        children: ["\u041A\u043E\u0434 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u0435\u043D \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 ", convertSecondsToHumanView(codeTtl)]
      }), _jsxs(Typography, {
        variant: "body2",
        children: ["\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043A\u043E\u0434 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E \u043C\u043E\u0436\u043D\u043E \u0447\u0435\u0440\u0435\u0437 ", timer, " \u0441\u0435\u043A\u0443\u043D\u0434"]
      })]
    }) : _jsx(_Fragment, {
      children: onGetNewCode && _jsx(Link, {
        color: "primary",
        component: Typography,
        underline: "hover",
        variant: "body2",
        onClick: onGetNewCode,
        children: "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043A\u043E\u0434 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E"
      })
    }), back && _jsxs(_Fragment, {
      children: [_jsx(Splitter, {
        size: 2
      }), back]
    })]
  });
};
export default CodeConfirmationDialog;