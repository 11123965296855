import { getCspOffersListRoute } from 'features/offer/csp/routes';
import { useHistory } from 'react-router';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SocialPackageAgreedActionsAdapter = _ref => {
  let {
    onNext
  } = _ref;
  const history = useHistory();
  const onAction = async () => {
    history.push(getCspOffersListRoute());
    onNext();
  };
  return _jsx(ConfirmGroupButtons, {
    children: _jsx(MPButton, {
      color: "brand",
      fullWidth: true,
      size: 'large',
      onClick: onAction,
      children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043C\u0435\u0441\u0442\u0430\u043C \u0434\u043B\u044F \u043E\u0442\u0434\u044B\u0445\u0430"
    })
  });
};