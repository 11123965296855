import { EBalanceType } from 'domain/model';
import { UserFinanceHistoryBonusesAdapter } from './historyBonuses';
import useCurrentUserBalances from '../../../current/hooks/useBalances';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceHistoryAdapter = props => {
  const {
    tab,
    guid
  } = props;
  const balancesData = useCurrentUserBalances();
  const isBalanceBonuses = balancesData?.find(balance => {
    return balance.type === EBalanceType.Bonuses;
  });
  switch (tab) {
    case EBalanceType.Bonuses:
      return isBalanceBonuses ? _jsx(UserFinanceHistoryBonusesAdapter, {
        guid: guid
      }) : null;
    case EBalanceType.ASP:
    case EBalanceType.CSP:
      return null;
  }
};