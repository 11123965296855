import { useEffect, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDialogueEvents } from '../';
import { DialogueEventDialogLayout } from '../layouts/dialog';
import { SlideContentWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * @experimental
 */
export const DialogueEventsComponentSwipeFlowAdapter = _ref => {
  let {
    activeIndex,
    events
  } = _ref;
  const {
    onNext,
    onClose
  } = useDialogueEvents();
  const event = events?.[activeIndex];
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeIndex);
    }
  }, [activeIndex, swiper]);
  return _jsx(DialogueEventDialogLayout, {
    open: true,
    disableEscapeKeyDown: event?.required,
    onClose: onClose,
    children: _jsx(Swiper, {
      slidesPerView: 1,
      allowTouchMove: false,
      noSwiping: true,
      loop: false,
      freeMode: true,
      onSwiper: setSwiper,
      children: events.map((_ref2, index) => {
        let {
          uniqueKey,
          type,
          payload,
          component: Component
        } = _ref2;
        return _jsx(SwiperSlide, {
          children: index <= activeIndex && _jsx(SlideContentWrapper, {
            active: index < activeIndex,
            children: Component &&
            //рисуются только слайды до текущего, чтобы не срабатывал раньше времени mount в будущих
            _jsx(Component, {
              uniqueKey: uniqueKey || type || '',
              payload: payload,
              onNext: onNext,
              onClose: onClose
            })
          })
        }, uniqueKey ?? type);
      })
    })
  });
};