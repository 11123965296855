import { SocialPackageRefuseEventContainer } from './container';
import { socialPackageRefuseEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: socialPackageRefuseEventType,
    component: SocialPackageRefuseEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};