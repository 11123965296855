import useTechConfig from 'hooks/useTechConfig';
import { BookingOfferOrderCspBannerHowUseAdapter } from './bannerHowUse';
import { EAppFeature } from 'presentation/types';
import { EOrderStatus } from 'domain/model';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferOrderBannerCspAdapter = _ref => {
  let {
    order
  } = _ref;
  const {
    hasFeature
  } = useTechConfig();
  const socialPackage = order.customerRzdSocialPackage;
  const isCspFeatureDisabled = !hasFeature(EAppFeature.Csp);
  const isOrderWithoutCsp = !order?.hasRzdSocialPackage;
  const isSocialPackageEmpty = !socialPackage;
  const isOrderCancelled = order.status === EOrderStatus.Cancelled;
  if (isCspFeatureDisabled || isOrderWithoutCsp || isSocialPackageEmpty || isOrderCancelled) {
    return null;
  }
  return _jsx(BookingOfferOrderCspBannerHowUseAdapter, {
    socialPackage: socialPackage
  });
};
export default BookingOfferOrderBannerCspAdapter;