import { Typography } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { getUserBalanceExpireMessage } from '../../utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UserBalanceExpire = _ref => {
  let {
    value,
    expireDate,
    disabled,
    balance
  } = _ref;
  return _jsx(MPChip, {
    size: "micro",
    color: disabled ? 'secondary' : 'warning',
    label: _jsx(Typography, {
      variant: "subtitle2",
      whiteSpace: "normal",
      children: getUserBalanceExpireMessage(value, expireDate, balance)
    })
  });
};
export default UserBalanceExpire;