import { Redirect, Switch } from 'react-router-dom';
import { AppConfigurator } from 'system/appConfigurator';
import { routing } from './routes';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CspOfferEntry = () => {
  return _jsx(Switch, {
    children: _jsx(Redirect, {
      path: routing.list,
      to: AppConfigurator.getInstance().getСorpCspOffersUrl()
    })
  });
};
export default CspOfferEntry;