import { useGetUserFavoritesBookingsOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import CardList from 'presentation/components/common/cardList';
import { useEffect } from 'react';
import OfferListItemContainer from '../../../../offer/listItem/container';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFavoritesBookingsOffersContainer = _ref => {
  let {
    guid,
    updateOnMount,
    onMount
  } = _ref;
  useEffect(() => {
    onMount();
  }, [onMount]);
  const {
    data,
    error
  } = useGetUserFavoritesBookingsOffersQuery({
    guid
  }, {
    refetchOnMountOrArgChange: updateOnMount
  });
  const offers = data?.data;
  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);
  if (!offers) {
    return null;
  }
  return _jsx(CardList, {
    children: offers.map(offer => _jsx(OfferListItemContainer, {
      offer: offer,
      favorite: {
        guid,
        stored: true /*чтобы не скрывался таб после убирания всех сердечек*/
      }
    }, offer.id))
  });
};