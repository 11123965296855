import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { useSelector } from 'react-redux';
import { bookingOfferIsFilterFetchingSelector, bookingOfferListNoDataNoFilterSelector, bookingOfferListNoDataSelector } from '../store/selectors';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BookingOfferListStubAdapter = _ref => {
  let {
    categoryId
  } = _ref;
  const noData = useSelector(bookingOfferListNoDataSelector);
  const noFilterNoData = useSelector(bookingOfferListNoDataNoFilterSelector);
  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);
  if (!categoryId && noFilterNoData || categoryId && !isFilterFetching && noData) {
    return _jsxs("div", {
      children: [_jsx(Typography, {
        variant: "body2",
        children: "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E."
      }), _jsx(Splitter, {
        size: 1
      }), _jsx(Typography, {
        variant: "body2",
        children: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B \u0444\u0438\u043B\u044C\u0442\u0440\u0430"
      })]
    });
  }
  return null;
};