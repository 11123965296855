import SocialPackageHowToUseContent from '../../../components/content/howToUse';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SocialPackageHowToUseContentAdapter = props => {
  const {
    payload: {
      socialPackage: {
        endDate,
        limitToSelfRest,
        limitToFamilyRest
      }
    }
  } = props;
  return _jsx(SocialPackageHowToUseContent, {
    endDate: endDate,
    limitToSelfRest: limitToSelfRest,
    limitToFamilyRest: limitToFamilyRest
  });
};