import { Typography } from '@mui/material';
import { bannerCspIcon, bannerCspIconAlt } from 'media/images';
import { MPButton } from 'theme/ui-kit/button';
import { Actions, Content, IconWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const getIconByVariant = variant => {
  switch (variant) {
    case 'primary':
      return bannerCspIcon;
    case 'secondary':
      return bannerCspIconAlt;
    case 'tertiary':
      return bannerCspIconAlt;
  }
};
const getColorButtonByVariant = variant => {
  switch (variant) {
    case 'primary':
      return 'white';
    case 'secondary':
      return 'primary';
    case 'tertiary':
      return 'primary';
  }
};
const getColorTextByVariant = variant => {
  switch (variant) {
    case 'primary':
      return 'white';
    case 'secondary':
      return 'textPrimary';
    case 'tertiary':
      return 'textPrimary';
  }
};
export const SocialPackageBanner = props => {
  const {
    onClick,
    variant = 'primary',
    children,
    actionText
  } = props;
  return _jsxs(Wrapper, {
    variant: variant,
    children: [_jsxs(Content, {
      children: [_jsx(IconWrapper, {
        children: _jsx("img", {
          src: getIconByVariant(variant),
          alt: ''
        })
      }), _jsx(Typography, {
        color: getColorTextByVariant(variant),
        variant: 'subtitle1',
        children: children
      })]
    }), onClick && actionText && _jsx(Actions, {
      children: _jsx(MPButton, {
        variant: 'outlined',
        color: getColorButtonByVariant(variant),
        fullWidth: true,
        onClick: onClick,
        children: actionText
      })
    })]
  });
};