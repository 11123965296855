import { DialogActions, DialogContent } from '@mui/material';
import { MPDialogContentBuilder } from 'theme/ui-kit/dialog/common/contentBuilder';
import { DialogTitle } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DialogueEventContentBuilderLayout = _ref => {
  let {
    wrapper,
    title,
    titleAsContent,
    actions,
    children: content
  } = _ref;
  return _jsxs(MPDialogContentBuilder, {
    wrapper: wrapper,
    title: !titleAsContent && title,
    children: [(content || title && titleAsContent) && _jsxs(DialogContent, {
      children: [titleAsContent && title && _jsx(DialogTitle, {
        children: title
      }), content]
    }), actions && _jsx(DialogActions, {
      children: actions
    })]
  });
};