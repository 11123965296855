import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { SocialPackageHowToUseActionsAdapter, SocialPackageHowToUseContentAdapter, SocialPackageHowToUseTitleAdapter } from './adapters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * успешное согласие с КСП
 */

export const SocialPackageHowToUseEventContainer = props => {
  return _jsx(DialogueEventContentBuilderLayout, {
    title: _jsx(SocialPackageHowToUseTitleAdapter, {}),
    actions: _jsx(SocialPackageHowToUseActionsAdapter, {
      ...props
    }),
    titleAsContent: true,
    children: _jsx(SocialPackageHowToUseContentAdapter, {
      ...props
    })
  });
};