import { Container } from '@mui/material';
import { offerSectionToTabMap } from 'presentation/features/header/utils';
import { getAspOffersListRoute } from 'presentation/features/offer/asp/routes';
import { getBookingOffersListRoute } from 'presentation/features/offer/booking/routes';
import { getCorpOffersListRoute } from 'presentation/features/offer/corp/routes';
import { getProductOfferListRoute } from 'presentation/features/offer/product/entry';
import { getTradeOffersListRoute } from 'presentation/features/offer/trade/routes';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Splitter from '../../components/common/splitter';
import useGridSpacing from '../../theme/hooks/useGridSpacing';
import { LinksWrapper, SeasonBg } from './controls';
import useLinks from './hooks/useLinks';
import { sectionLinksVisibleSelector } from './store/selectors';
import SeasonBackgroundImg from 'components/common/seasonBg';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SectionLinksContainer = () => {
  const {
    x3
  } = useGridSpacing();
  const matchedRoute = useRouteMatch([getAspOffersListRoute({}).pathname, getBookingOffersListRoute({}).pathname, getTradeOffersListRoute({}).pathname, getCorpOffersListRoute({}).pathname, getProductOfferListRoute({}).pathname]);
  const selected = offerSectionToTabMap.find(s => {
    return s.path === matchedRoute?.path;
  });
  const links = useLinks();
  const isVisible = useSelector(sectionLinksVisibleSelector);
  if (!isVisible) {
    return null;
  }
  return links && links.length > 0 && _jsxs(Container, {
    children: [_jsxs(LinksWrapper, {
      value: selected?.tab ?? false,
      textColor: "primary",
      variant: "scrollable",
      scrollButtons: false,
      children: [links, _jsx(SeasonBg, {
        children: _jsx(SeasonBackgroundImg, {})
      })]
    }), _jsx(Splitter, {
      size: x3
    })]
  });
};
export default SectionLinksContainer;