const brandBookColor = {
  white: 'hsla(0, 0%, 100%, 1)',
  black: 'hsla(220, 10%, 20%, 1)',
  red: 'hsla(349, 100%, 54%, 1)',
  blue: 'hsla(206, 100%, 45%, 1)',
  gray: 'hsla(220, 10%, 58%, 1)',
  orange: 'hsla(19, 100%, 67%, 1)',
  green: 'hsla(144, 100%, 40%, 1)',
  csp: 'hsla(219, 100%, 50%, 1)'
};
export default brandBookColor;