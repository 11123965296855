import { BalanceTabsAdapter, UserFinanceBalancesAdapter, UserFinanceBannersAdapter, UserFinancesLoaderAdapter } from './adapters';
import { UserFinanceHistoryAdapter } from './adapters/history';
import { UserFinancesLayout } from './layout';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Layout = UserFinancesLayout;
const UserFinancesEntryContainer = _ref => {
  let {
    guid,
    tab
  } = _ref;
  return _jsx(Layout, {
    tabs: _jsx(BalanceTabsAdapter, {
      tab: tab
    }),
    loader: _jsx(UserFinancesLoaderAdapter, {}),
    balances: _jsx(UserFinanceBalancesAdapter, {}),
    banners: _jsx(UserFinanceBannersAdapter, {}),
    history: _jsx(UserFinanceHistoryAdapter, {
      guid: guid,
      tab: tab
    })
  });
};
export default UserFinancesEntryContainer;