import { SocialPackageToConfirmationEventContainer } from './container';
import { socialPackageToConfirmationEventType } from './types';
export const createEvent = payload => {
  return {
    uniqueKey: `${socialPackageToConfirmationEventType}-${payload.socialPackage.id}`,
    required: true,
    type: socialPackageToConfirmationEventType,
    component: SocialPackageToConfirmationEventContainer,
    mergeWithNext: false,
    mergeWithPrev: false,
    payload
  };
};