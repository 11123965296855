import { Text } from '../../components/controls';
import { List, ListItem } from 'presentation/features/about/components/controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AboutCspWhatNeedDoInfo = () => {
  return _jsx(_Fragment, {
    children: _jsx(Text, {
      children: _jsxs(List, {
        as: 'ol',
        children: [_jsx(ListItem, {
          children: "\u041F\u043E\u0434\u0430\u0442\u044C \u0437\u0430\u044F\u0432\u043B\u0435\u043D\u0438\u0435 \u043D\u0430 \u0432\u044B\u0434\u0435\u043B\u0435\u043D\u0438\u0435 \u043F\u0443\u0442\u0451\u0432\u043A\u0438 \u0432 \u043A\u043E\u043C\u0438\u0441\u0441\u0438\u044E \u043F\u043E \u0440\u0430\u0441\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u0438\u044E \u043F\u0443\u0442\u0451\u0432\u043E\u043A \u0432\u0430\u0448\u0435\u0433\u043E \u043F\u043E\u0434\u0440\u0430\u0437\u0434\u0435\u043B\u0435\u043D\u0438\u044F;"
        }), _jsx(ListItem, {
          children: "\u0414\u043E\u0436\u0434\u0430\u0442\u044C\u0441\u044F \u0440\u0435\u0448\u0435\u043D\u0438\u044F \u043A\u043E\u043C\u0438\u0441\u0441\u0438\u0438 \u043F\u043E \u0440\u0430\u0441\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u0438\u044E \u043F\u0443\u0442\u0451\u0432\u043E\u043A;"
        }), _jsx(ListItem, {
          children: "\u0415\u0441\u043B\u0438 \u0440\u0435\u0448\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u043E\u0436\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0435: \u043F\u0440\u0438\u043E\u0431\u0440\u0435\u0441\u0442\u0438 \u043F\u0443\u0442\u0451\u0432\u043A\u0443 \u043D\u0430 \u043E\u0442\u0434\u044B\u0445;"
        }), _jsx(ListItem, {
          children: "\u041E\u0442\u0434\u043E\u0445\u043D\u0443\u0442\u044C;"
        }), _jsx(ListItem, {
          children: "\u041F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u043E\u043C\u0443 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u0443 \u0437\u0430 \u043F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u041A\u0421\u041F \u0432 \u0432\u0430\u0448\u0435\u043C \u043F\u043E\u0434\u0440\u0430\u0437\u0434\u0435\u043B\u0435\u043D\u0438\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B, \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u044E\u0449\u0438\u0435 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u0443\u0441\u043B\u0443\u0433\u0438;"
        }), _jsx(ListItem, {
          children: "\u041E\u0436\u0438\u0434\u0430\u0442\u044C \u0432\u044B\u043F\u043B\u0430\u0442\u0443 \u0432 \u0437\u0430\u0440\u043F\u043B\u0430\u0442\u0443."
        })]
      })
    })
  });
};
export default AboutCspWhatNeedDoInfo;