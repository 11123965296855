import Splitter from 'components/common/splitter';
import CompensationRulesDoc from '../../components/rulesDoc';
import PrivilegeListText from '../../components/text/privilegeListText';
import SummaryText from '../../components/text/summaryText';
import CompensationRest from '../compensatonRest';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SocialPackageDisagreedContent = props => {
  const {
    limitToSelfRest,
    limitToFamilyRest,
    endDate
  } = props;
  return _jsxs(_Fragment, {
    children: [_jsx(SummaryText, {
      endDate: endDate
    }), _jsx(Splitter, {
      size: 2
    }), _jsx(CompensationRest, {
      limitToFamilyRest: limitToFamilyRest,
      limitToSelfRest: limitToSelfRest
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(PrivilegeListText, {
      endDate: endDate
    }), _jsx(Splitter, {
      size: 2.5
    }), _jsx(CompensationRulesDoc, {})]
  });
};
export default SocialPackageDisagreedContent;