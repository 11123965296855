import { SocialPackageHowToUseEventContainer } from './container';
import { socialPackageHowToUseEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: socialPackageHowToUseEventType,
    component: SocialPackageHowToUseEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};