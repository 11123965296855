import { Typography } from '@mui/material';
import moment from 'moment/moment';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SummaryText = _ref => {
  let {
    endDate
  } = _ref;
  return _jsxs(Typography, {
    variant: "body2",
    children: ["\u0412\u0430\u043C \u043F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u0430 \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E\u0441\u0442\u044C \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0447\u0430\u0441\u0442\u044C \u0440\u0430\u0441\u0445\u043E\u0434\u043E\u0432 \u043D\u0430\xA0\u043E\u0437\u0434\u043E\u0440\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u0438\xA0\u043E\u0442\u0434\u044B\u0445 \u0432\xA0", moment(endDate).year(), "\xA0\u0433\u043E\u0434\u0443:"]
  });
};
export default SummaryText;