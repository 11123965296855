import winterBg from 'media/images/winterBg.png';
import moment from 'moment-timezone';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SeasonBackgroundImg = () => {
  const month = moment().month();
  switch (month) {
    case 0:
    case 11:
      return _jsx("img", {
        src: winterBg,
        alt: "winter season"
      });
    default:
      return null;
  }
};
export default SeasonBackgroundImg;