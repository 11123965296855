import { Typography } from '@mui/material';
import { InlineLink } from 'components/common/links/inline';
import Splitter from 'components/common/splitter';
import { EDateFormat } from 'domain/model';
import moment from 'moment-timezone';
import { Fragment } from 'react';
import ListNumber from '../../listNumber';
import { TextWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const getList = _ref => {
  let {
    endDate
  } = _ref;
  return [_jsx(Typography, {
    variant: "body2",
    children: "\u0417\u0430\u0431\u0440\u043E\u043D\u0438\u0440\u0443\u0439\u0442\u0435 \u0438\xA0\u043E\u043F\u043B\u0430\u0442\u0438\u0442\u0435 \u043F\u0443\u0442\u0451\u0432\u043A\u0443 \u043D\u0435\xA0\u043C\u0435\u043D\u0435\u0435 \u0447\u0435\u043C \u043D\u0430\xA07\xA0\u0434\u043D\u0435\u0439 \u043E\u0442\u0434\u044B\u0445\u0430."
  }, '1'), _jsxs(Typography, {
    variant: "body2",
    children: ["\u041F\u0435\u0440\u0435\u0434 \u0437\u0430\u0441\u0435\u043B\u0435\u043D\u0438\u0435\u043C \u043F\u0440\u0435\u0434\u0443\u043F\u0440\u0435\u0434\u0438\u0442\u0435 \u0430\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440\u0430, \u0447\u0442\u043E \u0432\u044B\xA0\u0440\u0430\u0431\u043E\u0442\u043D\u0438\u043A \u0420\u0416\u0414, \u0438\xA0\u0432\u0430\u043C \u043F\u043E\u043D\u0430\u0434\u043E\u0431\u044F\u0442\u0441\u044F", ' ', _jsx(InlineLink, {
      target: "_blank",
      href: '/support/Памятка КСП.pdf',
      children: "\u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u044E\u0449\u0438\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B (PDF)"
    }), "."]
  }, '2'), _jsxs(Typography, {
    variant: "body2",
    children: [_jsxs(Typography, {
      component: "span",
      variant: "subtitle2",
      children: ["\u0412\xA0\u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u043C\u0435\u0441\u044F\u0446\u0430, \u043D\u043E\xA0\u043D\u0435\xA0\u043F\u043E\u0437\u0434\u043D\u0435\u0435 ", moment(endDate).format(EDateFormat.Human)]
    }), ' ', "\u043F\u043E\u0441\u043B\u0435 \u043E\u0442\u0434\u044B\u0445\u0430 \u043F\u043E\u0434\u0430\u0435\u0442\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B \u043D\u0430\xA0\u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u044E \u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0435\u043D\u043D\u043E\u043C\u0443 \u0437\u0430\xA0\u043F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u041A\u0421\u041F \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u0443 \u043F\u043E\xA0\u0443\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044E \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u043E\u043C \u0432\xA0\u0432\u0430\u0448\u0435\u043C \u043F\u043E\u0434\u0440\u0430\u0437\u0434\u0435\u043B\u0435\u043D\u0438\u0438."]
  }, '3'), _jsx(Typography, {
    variant: "body2",
    children: "\u041E\u0436\u0438\u0434\u0430\u0439\u0442\u0435 \u043F\u043E\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F \u0432\u044B\u043F\u043B\u0430\u0442\u044B \u0432\u043C\u0435\u0441\u0442\u0435 \u0441\xA0\u043E\u0447\u0435\u0440\u0435\u0434\u043D\u043E\u0439 \u0437\u0430\u0440\u043F\u043B\u0430\u0442\u043E\u0439."
  }, '4')];
};
const PrivilegeListHowToGetText = _ref2 => {
  let {
    endDate
  } = _ref2;
  return _jsx(_Fragment, {
    children: getList({
      endDate
    }).map((item, index) => _jsxs(Fragment, {
      children: [index > 0 && _jsx(Splitter, {
        size: 2
      }), _jsxs(Wrapper, {
        children: [_jsx(ListNumber, {
          number: index + 1
        }), _jsx(TextWrapper, {
          children: item
        })]
      })]
    }, index))
  });
};
export default PrivilegeListHowToGetText;