import { locale } from 'locale';
const normalizeSumParts = parts => parts?.map(part => ({
  ...part,
  value: part.value.replace(new RegExp(' ', 'g'), '\u202f')
})) ?? null; //замена пробела на полупробел*/

const currencyToParts = function (n) {
  let maximumFractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (n === null) {
    return null;
  }
  return Intl.NumberFormat(locale.full, {
    style: 'currency',
    currency: locale.isoCode,
    maximumFractionDigits,
    minimumFractionDigits: 0
  }).formatToParts(n);
};
export const toRubCurrency = function (n) {
  let maximumFractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return normalizeSumParts(currencyToParts(n, maximumFractionDigits))?.map(part => part.value)?.join('')?.trimEnd() ?? null;
};
export const toCspRubCurrency = n => {
  return toRubCurrency(n, 2);
};
export const toCurrency = function (n) {
  let maximumFractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return normalizeSumParts(currencyToParts(n, maximumFractionDigits))?.filter(part => part.type !== 'currency')?.map(part => part.value)?.join('')?.trimEnd() ?? null;
};