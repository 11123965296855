import { UserFinancesBalanceLayout } from '../layout';
import { BalanceSlotMainBar } from '../layout/controls';
import { UserFinanceBalanceSlotActionsAdapter, UserFinanceBalanceSlotExpireAdapter, UserFinanceBalanceSlotTitleAdapter, UserFinanceBalanceSlotValueAdapter } from './balanceSlot';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceBonusesAdapter = props => {
  const {
    balance
  } = props;
  return _jsx(UserFinancesBalanceLayout, {
    slots: [_jsx(UserFinanceBalanceSlotExpireAdapter, {
      balance: balance
    }, 'expire'), _jsxs(BalanceSlotMainBar, {
      children: [_jsx(UserFinanceBalanceSlotTitleAdapter, {
        balance: balance
      }, 'title'), _jsx(UserFinanceBalanceSlotValueAdapter, {
        balance: balance
      }, 'value'), _jsx(UserFinanceBalanceSlotActionsAdapter, {
        balance: balance
      }, 'actions')]
    }, 'mainBar')]
  });
};