import { ESocialPackageStatus } from 'domain/model';
import { BalanceSlotMainBar } from 'features/user/details/finances/layout/controls';
import { UserFinancesBalanceLayout } from '../layout';
import { UserFinanceBalanceSlotActionsCspAdapter, UserFinanceBalanceSlotTitleAdapter, UserFinanceBalanceSlotValueAdapter } from './balanceSlot';
import { UserFinanceBalanceSlotExpireCspAdapter } from './balanceSlot/expireCsp';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceCspAdapter = props => {
  const {
    balance
  } = props;
  const isDisabled = balance.source.status === ESocialPackageStatus.Upcoming;
  return _jsx(UserFinancesBalanceLayout, {
    slots: [_jsx(UserFinanceBalanceSlotExpireCspAdapter, {
      balance: balance,
      disabled: isDisabled
    }, 'expire'), _jsxs(BalanceSlotMainBar, {
      children: [_jsx(UserFinanceBalanceSlotTitleAdapter, {
        balance: balance,
        disabled: isDisabled
      }), _jsx(UserFinanceBalanceSlotValueAdapter, {
        balance: balance,
        disabled: isDisabled
      }), _jsx(UserFinanceBalanceSlotActionsCspAdapter, {
        balance: balance
      })]
    }, 'mainBar')]
  });
};