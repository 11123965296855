import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { SocialPackageAgreedActionsAdapter, SocialPackageAgreedContentAdapter, SocialPackageAgreedTitleAdapter } from './adapters';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * успешное согласие с КСП
 */

export const SocialPackageAgreedEventContainer = props => {
  return _jsx(DialogueEventContentBuilderLayout, {
    title: _jsx(SocialPackageAgreedTitleAdapter, {}),
    actions: _jsx(SocialPackageAgreedActionsAdapter, {
      ...props
    }),
    children: _jsx(SocialPackageAgreedContentAdapter, {})
  });
};