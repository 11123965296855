import useTechConfig from 'hooks/useTechConfig';
import { EAppFeature } from 'presentation/types';
import { createEvent as createEventHowToUse } from '../../../../socialPackage/events/howToUse';
import { SocialPackageBanner } from '../../../../socialPackage/components/banners';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOrderBannerCspAdapter = _ref => {
  let {
    order
  } = _ref;
  const {
    hasFeature
  } = useTechConfig();
  const socialPackage = order.customerRzdSocialPackage;
  const {
    publish
  } = useBusinessEventBus();

  // Если флаг ксп отключен  или в заказе создан без ксп, или сейчас нет соц пакета в Active
  if (!hasFeature(EAppFeature.Csp) || !order?.hasRzdSocialPackage || !socialPackage) {
    return null;
  }
  return _jsx(SocialPackageBanner, {
    variant: 'tertiary',
    actionText: 'Как получить компенсацию',
    onClick: () => {
      publish(createEventHowToUse({
        socialPackage
      }));
    },
    children: "\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u0443\u0439\u0442\u0435 \u0447\u0430\u0441\u0442\u044C \u0441\u0432\u043E\u0438\u0445 \u0437\u0430\u0442\u0440\u0430\u0442 \u043D\u0430 \u043E\u0437\u0434\u043E\u0440\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u0438 \u043E\u0442\u0434\u044B\u0445"
  });
};
export default BookingOrderBannerCspAdapter;