import { Wrapper } from './controls';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const NumberedText = props => {
  const {
    number,
    children
  } = props;
  return _jsxs(Wrapper, {
    children: [_jsxs("span", {
      children: [number, "."]
    }), children]
  });
};