import { EBalanceType, ESocialPackageStatus } from 'domain/model';
import useCurrentUserBalances from 'features/user/current/hooks/useBalances';
import { UserFinanceBalanceAdapter } from './balance';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const isDisplayDisabledBalance = true;
export const UserFinanceBalancesAdapter = () => {
  const balancesData = useCurrentUserBalances();
  if (!balancesData) {
    return null;
  }
  const filteredBalancesData = isDisplayDisabledBalance ? balancesData : balancesData.filter(item => {
    return !(item.type === EBalanceType.CSP && item.source.status === ESocialPackageStatus.Upcoming);
  });
  return filteredBalancesData.map((balance, index) => _jsx(UserFinanceBalanceAdapter, {
    balance: balance
  }, index));
};