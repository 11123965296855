import { Fragment, useContext } from 'react';
import { MPCommonDialogContext } from 'theme/ui-kit/dialog/common/context';
import { InnerWrapper, StyledDialogTitle } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MPDialogContentBuilder = _ref => {
  let {
    wrapper: Wrapper = Fragment,
    title,
    children
  } = _ref;
  const {
    isBackButtonVisible
  } = useContext(MPCommonDialogContext);
  return _jsx(Wrapper, {
    children: _jsxs(InnerWrapper, {
      children: [title && _jsx(StyledDialogTitle, {
        compact: !!isBackButtonVisible,
        children: title
      }), children]
    })
  });
};