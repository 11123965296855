import { Typography } from '@mui/material';
import Splitter from 'components/common/splitter';
import { GreenOKIcon } from 'presentation/media/icons';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingOfferOrderHeader = _jsxs(_Fragment, {
  children: [_jsxs(Typography, {
    variant: "h1",
    children: ["\u0417\u0430\u043A\u0430\u0437 \u0441\u043E\u0437\u0434\u0430\u043D \xA0", _jsx(GreenOKIcon, {})]
  }), _jsx(Splitter, {
    size: 2
  }), _jsx(Typography, {
    variant: "body2",
    children: "\u0414\u043E\u0436\u0434\u0438\u0442\u0435\u0441\u044C \u0437\u0432\u043E\u043D\u043A\u0430 \u043E\u0442 \u043F\u0440\u0435\u0434\u0441\u0442\u0430\u0432\u0438\u0442\u0435\u043B\u044F \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u0434\u043B\u044F \u0441\u043E\u0433\u043B\u0430\u0441\u043E\u0432\u0430\u043D\u0438\u044F \u0434\u0435\u0442\u0430\u043B\u0435\u0439 \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u0438 \u043E\u043A\u043E\u043D\u0447\u0430\u0442\u0435\u043B\u044C\u043D\u043E\u0439 \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u0438."
  })]
});
export default BookingOfferOrderHeader;