import { Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { getAboutCspRoute } from '../../../../about/entry';
import { SocialPackageBanner } from '../../../../socialPackage/components/banners';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BookingOfferCspBannerAboutAdapter = () => {
  const history = useHistory();
  return _jsx(SocialPackageBanner, {
    variant: 'tertiary',
    actionText: 'Узнать о льготе',
    onClick: () => {
      history.push(getAboutCspRoute());
    },
    children: _jsx(Typography, {
      children: "\u0412\u043E\u0437\u0432\u0440\u0430\u0449\u0430\u0435\u043C \u0447\u0430\u0441\u0442\u044C \u0437\u0430\u0442\u0440\u0430\u0442 \u043D\u0430 \u043E\u0437\u0434\u043E\u0440\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u0438 \u043E\u0442\u0434\u044B\u0445 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044F\u043C \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u0443\u0435\u043C\u043E\u0433\u043E \u0441\u043E\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u043F\u0430\u043A\u0435\u0442\u0430"
    })
  });
};