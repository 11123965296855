import { useAgreeSocialPackageMutation } from 'api/user';
import ContentLoader from 'components/common/loader';
import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SocialPackageToAgreementActionsAdapter = props => {
  const {
    payload: {
      socialPackage: {
        id
      }
    },
    onAgreed,
    onRefuse
  } = props;
  const [onAgree, {
    isLoading
  }] = useAgreeSocialPackageMutation();
  const onAgreeInternal = async () => {
    await onAgree({
      socialPackageId: id
    });
    onAgreed();
  };
  return _jsxs(ConfirmGroupButtons, {
    children: [_jsxs(MPButton, {
      color: "brand",
      fullWidth: true,
      size: 'large',
      onClick: onAgreeInternal,
      disabled: isLoading,
      children: ["\u0421\u043E\u0433\u043B\u0430\u0441\u0438\u0442\u044C\u0441\u044F \u043D\u0430 \u043B\u044C\u0433\u043E\u0442\u0443", isLoading && _jsx(ContentLoader, {})]
    }), _jsx(MPButton, {
      color: "brand",
      variant: "outlined",
      size: 'large',
      fullWidth: true,
      onClick: onRefuse,
      children: "\u041E\u0442\u043A\u0430\u0437\u0430\u0442\u044C\u0441\u044F"
    })]
  });
};