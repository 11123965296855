import { Typography } from '@mui/material';
import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { SocialPackageBanner } from 'features/socialPackage/components/banners';
import { createEvent as createEventToAgreement } from 'features/socialPackage/events/toAgreement';
import { socialPackagesFirstReadySelector } from 'features/socialPackage/store/selectors';
import { toCspRubCurrency } from 'presentationUtils/currency';
import { useSelector } from 'react-redux';
import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBannersAdapter = () => {
  const readySocialPackage = useSelector(socialPackagesFirstReadySelector);
  const {
    publish
  } = useBusinessEventBus();
  if (!readySocialPackage) {
    return null;
  }
  const onClick = () => publish(createEventToAgreement({
    socialPackage: readySocialPackage
  }));
  return _jsx(SocialPackageBanner, {
    onClick: onClick,
    actionText: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435",
    variant: "primary",
    children: _jsxs(Typography, {
      variant: "subtitle1",
      children: ["\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u0443\u0435\u043C \u0434\u043E ", toCspRubCurrency(readySocialPackage.maxLimit), " \u043E\u0442 \u0432\u0430\u0448\u0438\u0445 \u0437\u0430\u0442\u0440\u0430\u0442 \u043D\u0430 \u043E\u0437\u0434\u043E\u0440\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u0438 \u043E\u0442\u0434\u044B\u0445"]
    })
  });
};