import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { createEvent } from 'features/socialPackage/events/howToUse';
import { MPButton } from 'theme/ui-kit/button';
import { BalanceSlotActions } from '../../layout/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotActionsCspAdapter = _ref => {
  let {
    balance
  } = _ref;
  const {
    publish
  } = useBusinessEventBus();
  const onGetInfo = () => {
    publish(createEvent({
      socialPackage: balance.source
    }));
  };
  return _jsx(BalanceSlotActions, {
    children: _jsx(MPButton, {
      variant: "outlined",
      color: "brand",
      onClick: onGetInfo,
      fullWidth: true,
      children: "\u041A\u0430\u043A \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u044E"
    })
  });
};