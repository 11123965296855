import { useMediaQuery } from '@mui/material';
import { EOfferClientTagType, EOfferServerTagType } from '../../../../../domain/model/enums';
import { AspCurrencyIcon } from '../../../../media/icons';
import { MPChip } from '../../../../theme/ui-kit/chip';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOfferTagIcon = offerTagType => {
  switch (offerTagType) {
    case EOfferServerTagType.Hit:
    case EOfferServerTagType.Sale:
    case EOfferServerTagType.New:
    case EOfferClientTagType.CSP:
      return _jsx(_Fragment, {});
    case EOfferClientTagType.ASP:
      return _jsx(AspCurrencyIcon, {});
  }
};
const getOfferTagColor = offerTagType => {
  switch (offerTagType) {
    case EOfferServerTagType.Hit:
      return 'warning';
    case EOfferServerTagType.Sale:
      return 'error';
    case EOfferServerTagType.New:
      return 'success';
    case EOfferClientTagType.CSP:
      return 'csp';
    case EOfferClientTagType.ASP:
      return 'white';
  }
};
const OfferTag = props => {
  const {
    offerTag,
    size = 'small'
  } = props;
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  return _jsx(MPChip, {
    size: isMdUp ? size : 'micro',
    icon: getOfferTagIcon(offerTag.type),
    color: getOfferTagColor(offerTag.type),
    variant: "filled",
    label: offerTag.name
  });
};
export default OfferTag;