import { Number } from '../../components/listNumber/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ListNumber = _ref => {
  let {
    number
  } = _ref;
  return _jsx(Number, {
    variant: "subtitle1",
    children: number
  });
};
export default ListNumber;