import UserFavoritesStubAdapter from 'presentation/features/user/details/favorites/adapters/stub';
import useEntityScrollPositions from 'presentation/features/user/details/favorites/hooks/useEntityScrollPositions';
import useMountCandidates from 'presentation/features/user/details/favorites/hooks/useMountCandidates';
import UserDetailsLayout from 'presentation/layouts/userDetails';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { getUserFavoritesRoute } from '../../entry';
import UserFavoritesLoaderAdapter from './adapters/loader';
import { useUserFavoritesTabs } from './hooks/useTabs';
import { UserFavoritesBookingsOffersContainer, UserFavoritesCorpOffersContainer, UserFavoritesProductOffersContainer, UserFavoritesTabs, UserFavoritesTradeOffersContainer } from './tabs';
import { EUserFavoritesTab } from './utils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Layout = UserDetailsLayout;
const UserFavoritesContainer = props => {
  const {
    guid,
    tab,
    isCorpUser
  } = props;
  const history = useHistory();
  const {
    scrollToPosition,
    updateScrollPosition,
    scrollPositions
  } = useEntityScrollPositions();
  const {
    excludeCandidate,
    hasCandidate
  } = useMountCandidates();
  const {
    tabList,
    tabToDisplay
  } = useUserFavoritesTabs({
    guid,
    tab,
    isCorpUser
  });
  const onChangeTab = useCallback(newTab => {
    history.replace(getUserFavoritesRoute({
      guid,
      tab: newTab
    }));
    updateScrollPosition(tab, window.scrollY);
  }, [guid, history, tab, updateScrollPosition]);
  const tabsComponent = useMemo(() => _jsx(UserFavoritesTabs, {
    tab: tabToDisplay,
    tabs: tabList,
    onChangeTab: onChangeTab
  }), [tabToDisplay, tabList, onChangeTab]);
  const onMountTabContainer = offerTypeTabContainer => {
    excludeCandidate(offerTypeTabContainer);
    if (typeof scrollPositions[offerTypeTabContainer] === 'number') {
      scrollToPosition(scrollPositions[offerTypeTabContainer]);
    }
  };
  return _jsxs(Layout, {
    header: "\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435",
    toolbar: tabsComponent,
    loader: _jsx(UserFavoritesLoaderAdapter, {
      guid: guid
    }),
    children: [tabToDisplay?.value === EUserFavoritesTab.ProductOffers && _jsx(UserFavoritesProductOffersContainer, {
      guid: guid,
      updateOnMount: hasCandidate(EUserFavoritesTab.ProductOffers),
      onMount: () => onMountTabContainer(EUserFavoritesTab.ProductOffers)
    }), tabToDisplay?.value === EUserFavoritesTab.TradeOffers && _jsx(UserFavoritesTradeOffersContainer, {
      guid: guid,
      updateOnMount: hasCandidate(EUserFavoritesTab.TradeOffers),
      onMount: () => onMountTabContainer(EUserFavoritesTab.TradeOffers)
    }), tabToDisplay?.value === EUserFavoritesTab.CorpOffers && _jsx(UserFavoritesCorpOffersContainer, {
      guid: guid,
      updateOnMount: hasCandidate(EUserFavoritesTab.CorpOffers),
      onMount: () => onMountTabContainer(EUserFavoritesTab.CorpOffers)
    }), tabToDisplay?.value === EUserFavoritesTab.BookingOffers && _jsx(UserFavoritesBookingsOffersContainer, {
      guid: guid,
      updateOnMount: hasCandidate(EUserFavoritesTab.BookingOffers),
      onMount: () => onMountTabContainer(EUserFavoritesTab.BookingOffers)
    }), _jsx(UserFavoritesStubAdapter, {
      countGuid: guid,
      tabsCount: tabList.length
    })]
  });
};
export default UserFavoritesContainer;