import AppDecorators from 'app/decorators';
import AppInfrastructure from 'app/infrastructure';
import ContentLoader from 'components/common/loader';
import HttpClient from 'data/network/http';
import store from 'data/store/store';
import AboutEntry from 'features/about/entry';
import Protect from 'features/auth/protect';
import AuthProvider from 'features/auth/provider';
import { useAuth } from 'features/auth/provider/useAuth';
import ErrorBoundary from 'features/general/errorBoundary';
import LegalEntry from 'features/legal/entry';
import MainContainer from 'features/main/container';
import UserSettingsProvider from 'features/user/current/userSettingsProvider';
import useTechConfig from 'hooks/useTechConfig';
import SentryClient from 'integration/sentry/client';
import { UserThemeProvider } from 'presentation/features/user/theme/provider';
import { MPThemeProvider, theme } from 'presentation/theme';
import { Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import SystemNotifier from 'system/notifier';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const App = () => {
  HttpClient.getInstance().init(store.dispatch);
  SystemNotifier.getInstance().init(store.dispatch);
  return _jsxs(_Fragment, {
    children: [_jsx(AuthProvider, {}), _jsxs(Switch, {
      children: [_jsx(Route, {
        path: rootRouting.legal,
        render: () => _jsx(MPThemeProvider, {
          theme: theme,
          children: _jsx(LegalEntry, {})
        })
      }), _jsx(Route, {
        path: rootRouting.about,
        render: () => _jsx(MPThemeProvider, {
          theme: theme,
          children: _jsx(AboutEntry, {})
        })
      }), _jsx(AppInternal, {})]
    })]
  });
};
const AppInternal = () => {
  const {
    isLoading,
    isAuthenticated,
    logOut
  } = useAuth();
  const {
    isGuestModeDisabled
  } = useTechConfig();
  if (isLoading) {
    return _jsx(ContentLoader, {});
  }
  return _jsx(ErrorBoundary, {
    variant: SentryClient.getInstance().isActive() ? 'sentry' : 'common',
    onLogout: isAuthenticated && logOut,
    children: _jsx(UserThemeProvider, {
      children: _jsx(Protect, {
        ignore: !isGuestModeDisabled,
        children: _jsxs(UserSettingsProvider, {
          children: [_jsx(AppInfrastructure, {}), _jsx(AppDecorators, {
            children: _jsx(MainContainer, {})
          })]
        })
      })
    })
  });
};
export default App;