import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { ESocialPackageStatus } from 'domain/model';
import { useEffect } from 'react';
import { createEvent as createEventToAgreement } from '../events/toAgreement';
import { createEvent as createEventToConfirmation } from '../events/toConfirmation';
import { useSelector } from 'react-redux';
import { socialPackagesToUserAttentionSelector } from '../store/selectors';
const SocialPackageListenerContainer = () => {
  const {
    publish
  } = useBusinessEventBus();
  const socialPackages = useSelector(socialPackagesToUserAttentionSelector);
  useEffect(() => {
    if (!socialPackages?.length) {
      return;
    }

    /**
     * задача селектора вернуть всё, требующее внимания пользователя, поэтому тут ничего не преобразовываем
     */
    const socialPackage = socialPackages[0];
    switch (socialPackage.status) {
      case ESocialPackageStatus.Ready:
        publish(createEventToAgreement({
          socialPackage
        }));
        break;
      case ESocialPackageStatus.Active:
      case ESocialPackageStatus.Upcoming:
        publish(createEventToConfirmation({
          socialPackage
        }));
        break;
      case ESocialPackageStatus.Appointed:
      case ESocialPackageStatus.Archived:
        break;
    }
  }, [socialPackages, publish]);
  return null;
};
export default SocialPackageListenerContainer;