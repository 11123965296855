import { MPDialog } from 'theme/ui-kit/dialog';
import { ContentWrapper } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const DialogueEventDialogLayout = _ref => {
  let {
    children,
    ...others
  } = _ref;
  return _jsx(MPDialog, {
    fullWidth: true,
    keepMounted: true,
    maxWidth: "xs",
    fullScreenBreakpoint: "xs",
    closeToBackButtonInFullScreen: false,
    externalBuilder: true,
    scroll: "paper",
    ...others,
    children: _jsx(ContentWrapper, {
      children: children
    })
  });
};