import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { SocialPackageToConfirmationActionsAdapter, SocialPackageToConfirmationContentAdapter, SocialPackageToConfirmationTitleAdapter } from './adapters';
import { useSocialPackageToConfirmation } from './useToConfirmation';

/**
 * состояние КСП
 *
 * есть признак КСП у пользователя
 * согласился за пределами системы
 * готов к активации или уже согласован {@link ESocialPackageStatus.Ready} {@link ESocialPackageStatus.Active} {@link ESocialPackageStatus.Upcoming}
 * при закрытии отправляется автоматическое согласие
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SocialPackageToConfirmationEventContainer = props => {
  useSocialPackageToConfirmation(props.payload.socialPackage);
  return _jsx(DialogueEventContentBuilderLayout, {
    title: _jsx(SocialPackageToConfirmationTitleAdapter, {
      ...props
    }),
    actions: _jsx(SocialPackageToConfirmationActionsAdapter, {
      ...props
    }),
    titleAsContent: true,
    children: _jsx(SocialPackageToConfirmationContentAdapter, {
      ...props
    })
  });
};