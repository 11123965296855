import { getAboutCspRoute } from 'features/about/entry';
import { DocIcon2 } from 'media/icons';
import { MPLink } from 'theme/ui-kit/link';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const CompensationRulesDoc = () => {
  return _jsx(MPLink, {
    startIcon: DocIcon2,
    href: getAboutCspRoute(),
    target: "_blank",
    children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u044B\u0435 \u043F\u0440\u0430\u0432\u0438\u043B\u0430 \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u044B"
  });
};
export default CompensationRulesDoc;