import { EAddressLevel } from 'domain/model/enums';
import { AddressHelper } from 'utils/address';
import AddressSingleSelector from './index';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const getOptionLabelDefault = address => new AddressHelper(address).getLocalityFullPath();
const AddressCitySingleSelector = props => {
  return _jsx(AddressSingleSelector, {
    ...props,
    noOptionsText: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435",
    getOptionLabel: props.getOptionLabel ?? getOptionLabelDefault,
    level: EAddressLevel.Value5
  });
};
export default AddressCitySingleSelector;