import { Typography } from '@mui/material';
import { StyledTab, StyledTabs } from './controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AboutTabsComponent = _ref => {
  let {
    value,
    tabs,
    onChange
  } = _ref;
  return _jsx(StyledTabs, {
    textColor: "secondary",
    value: value?.id ?? tabs[0].id,
    orientation: "vertical",
    indicatorColor: "secondary",
    children: tabs.map(tab => _jsx(StyledTab, {
      label: _jsx(Typography, {
        variant: 'subtitle2',
        children: tab.name
      }),
      value: tab.id,
      onClick: () => {
        onChange(tab);
      }
    }, tab.id))
  });
};
export default AboutTabsComponent;