import { getBookingOffersDetailsRoute } from 'presentation/features/offer/booking/routes';
import { UserIcon } from 'presentation/media/icons';
import { useHistory } from 'react-router';
import { EBookingOfferDetailsTab } from '../../../../../offer/booking/details/types';
import Option from './option';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Icon = _jsx(UserIcon, {});
const ContactsOption = _ref => {
  let {
    offerId
  } = _ref;
  const history = useHistory();
  return _jsx(_Fragment, {
    children: _jsx(Option, {
      icon: Icon,
      text: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B \u043E\u0431\u044A\u0435\u043A\u0442\u0430",
      onClick: () => history.push(getBookingOffersDetailsRoute({
        id: offerId,
        tab: EBookingOfferDetailsTab.Contacts
      }))
    })
  });
};
export default ContactsOption;