import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import AboutAspScreen from 'screen/about/asp';
import AboutCspScreen from 'screen/about/csp';
import AboutPublicOfferScreen from 'screen/about/publicOffer';
import { EAppFeature } from '../../types';
import { AppRoute } from '../general/routing';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const root = rootRouting.about;
const routing = {
  root,
  publicOffer: `${root}/public-offer`,
  asp: `${root}/asp`,
  csp: `${root}/csp`
};
export const getAboutRootRoute = () => routing.root;
export const getAboutCspRoute = () => routing.csp;
const AboutEntry = () => {
  return _jsxs(Switch, {
    children: [_jsx(Route, {
      exact: true,
      path: routing.publicOffer,
      component: AboutPublicOfferScreen
    }), _jsx(AppRoute, {
      exact: true,
      protect: true,
      path: routing.asp,
      component: AboutAspScreen,
      feature: EAppFeature.Asp
    }), _jsx(AppRoute, {
      exact: true,
      protect: true,
      path: routing.csp,
      component: AboutCspScreen,
      feature: EAppFeature.Csp
    }), _jsx(Redirect, {
      to: rootRouting.root
    })]
  });
};
export default AboutEntry;