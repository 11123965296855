import BackgroundContainer from '../backgroundContainer';
import { ContentBarGrid, ContentBarGridArea, InnerLayoutGrid, InnerLayoutGridArea, ParentLayoutGrid, ParentLayoutGridArea, SidebarGrid, SidebarGridArea } from './controls';
import { EContentbarGridAreas, EInnerGridAreas, EParentGridAreas, ESidebarGridAreas } from './types';
import { useMediaQuery } from '@mui/material';
import useHideHeader from '../../features/header/hooks/useHideHeader';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingDetailLayout = props => {
  const {
    sidebar,
    images,
    description,
    descriptionFixedWidth,
    partnerInfo,
    smartphoneFixedBar,
    content,
    title,
    topper,
    breadcrumb,
    close,
    favorite,
    fixedPositionsAreas,
    banner
  } = props;
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  useHideHeader(isMdDown);
  return _jsxs(ParentLayoutGrid, {
    children: [_jsx(ParentLayoutGridArea, {
      area: EParentGridAreas.Bar,
      children: topper
    }), _jsx(ParentLayoutGridArea, {
      area: EParentGridAreas.Inner,
      children: _jsx(BackgroundContainer, {
        close: close,
        children: _jsxs(InnerLayoutGrid, {
          children: [_jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.SmartphoneFixedBar,
            children: smartphoneFixedBar
          }), _jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.Breadcrumbs,
            children: breadcrumb
          }), _jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.Topbar,
            children: title
          }), _jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.Favorite,
            children: favorite
          }), _jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.Contentbar,
            children: _jsxs(ContentBarGrid, {
              fixedPositionsAreas: fixedPositionsAreas,
              descriptionFixedWidth: descriptionFixedWidth,
              children: [_jsx(ContentBarGridArea, {
                area: EContentbarGridAreas.Breadcrumbs,
                children: breadcrumb
              }), !fixedPositionsAreas?.includes(EContentbarGridAreas.SmartphoneTitle) && _jsx(ContentBarGridArea, {
                area: EContentbarGridAreas.SmartphoneTitle,
                children: title
              }), _jsx(ContentBarGridArea, {
                area: EContentbarGridAreas.Image,
                children: images
              }), _jsx(ContentBarGridArea, {
                area: EContentbarGridAreas.Banner,
                children: banner
              }), _jsx(ContentBarGridArea, {
                area: EContentbarGridAreas.Description,
                children: description
              }), _jsx(ContentBarGridArea, {
                area: EContentbarGridAreas.LargeDesktopTabs,
                children: content
              })]
            })
          }), _jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.Sidebar,
            children: _jsxs(SidebarGrid, {
              children: [_jsx(SidebarGridArea, {
                area: ESidebarGridAreas.Actions,
                children: sidebar
              }), _jsx(SidebarGridArea, {
                area: ESidebarGridAreas.PartnerInfo,
                children: partnerInfo
              })]
            })
          }), !fixedPositionsAreas?.includes(EInnerGridAreas.Bottombar) && _jsx(InnerLayoutGridArea, {
            area: EInnerGridAreas.Bottombar,
            children: content
          })]
        })
      })
    })]
  });
};
export default BookingDetailLayout;