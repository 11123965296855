import { SocialPackageToAgreementEventContainer } from './container';
import { socialPackageToAgreementEventType } from './types';
export const createEvent = payload => {
  return {
    uniqueKey: `${socialPackageToAgreementEventType}-${payload.socialPackage.id}`,
    required: true,
    type: socialPackageToAgreementEventType,
    component: SocialPackageToAgreementEventContainer,
    mergeWithNext: true,
    mergeWithPrev: false,
    payload
  };
};