import { ESocialPackageStatus } from 'domain/model';
import UserBalanceExpire from '../../../../components/balanceExpire';
import { BalanceSlotExpire } from '../../layout/controls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFinanceBalanceSlotExpireCspAdapter = _ref => {
  let {
    balance,
    disabled = false
  } = _ref;
  const expireDate = balance.source.status !== ESocialPackageStatus.Upcoming ? balance.source.endDate : balance.source.startDate;
  return expireDate ? _jsx(BalanceSlotExpire, {
    children: _jsx(UserBalanceExpire, {
      value: balance.value ?? 0,
      disabled: disabled,
      expireDate: expireDate,
      balance: balance
    })
  }) : null;
};