import ContentLoader from 'presentation/components/common/loader';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import BookingOfferOrderDetailsAdapter from '../../../user/details/order/booking/adapters/details';
import useBookingOfferOrderData from './hooks/useBookingOfferOrderData';
import BookingOfferOrderHeader from '../components/order/header';
import BookingOfferOrderButtons from '../components/order/buttons';
import { OrderDetailsWrapper } from '../components/order/controls';
import { OrderCreatedLayout } from 'layouts/orderCreated';
import BookingOrderBannerCspAdapter from './adapters/cspBanner';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const BookingOfferOrderContainer = _ref => {
  let {
    id
  } = _ref;
  const {
    order,
    isFetching
  } = useBookingOfferOrderData(id);
  return _jsx(MPGrid, {
    container: true,
    justifyContent: "center",
    children: _jsx(OrderCreatedLayout, {
      header: BookingOfferOrderHeader,
      subHeader: order && _jsx(BookingOrderBannerCspAdapter, {
        order: order
      }),
      footer: _jsx(BookingOfferOrderButtons, {}),
      loader: isFetching && _jsx(ContentLoader, {
        position: "fixed"
      }),
      details: order && _jsx(OrderDetailsWrapper, {
        children: _jsx(BookingOfferOrderDetailsAdapter, {
          order: order
        })
      })
    })
  });
};
export default BookingOfferOrderContainer;