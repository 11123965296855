import { toCspRubCurrency } from 'presentationUtils/currency';
export const SocialPackageToAgreementTitleAdapter = _ref => {
  let {
    payload
  } = _ref;
  const {
    socialPackage: {
      maxLimit
    }
  } = payload;
  return `Компенсируем до\u00A0${toCspRubCurrency(maxLimit)} от\u00A0ваших затрат на\u00A0оздоровление и отдых`;
};