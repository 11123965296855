import rootRouting from 'routing';
import { v4 as uuidv4 } from 'uuid';
const root = rootRouting.cspOffer;
export const routing = {
  list: root
};
export const getCspOffersListRoute = props => {
  const newGuid = props?.guid ?? uuidv4();
  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid
    },
    hash: ''
  };
};