import { useGetUserFavoritesProductOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import CardList from 'presentation/components/common/cardList';
import { useEffect } from 'react';
import ProductOfferCard from '../../../../offer/product/components/card';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserFavoritesProductOffersContainer = _ref => {
  let {
    guid,
    updateOnMount,
    onMount
  } = _ref;
  useEffect(() => {
    onMount();
  }, [onMount]);
  const {
    data,
    error: productOffersError
  } = useGetUserFavoritesProductOffersQuery({
    guid
  }, {
    refetchOnMountOrArgChange: updateOnMount
  });
  const productOffers = data?.data;

  /*const {
    isFetching: isAddToFavoritesFetching,
    isRemoved: isFavoriteRemoved,
    canAdd,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
    },
    clearAfterUnmount: false,
  });*/

  useEffect(() => {
    if (productOffersError) {
      ErrorHandler.handleHttpError(productOffersError);
    }
  }, [productOffersError]);
  if (!productOffers) {
    return null;
  }
  return _jsx(CardList, {
    span: 4,
    children: productOffers.map(offer => _jsx(ProductOfferCard, {
      product: offer
      /*toRemove={isFavoriteRemoved(EOfferType.Product, offer)}
      favorite={{
        position: EProductOfferFavoritePosition.Details,
        active: canAdd(EOfferType.Product, offer) && isFavorite(EOfferType.Product, offer),
        inProgress: isAddToFavoritesFetching(offer.id),
        onClick: () => addOrRemoveFavorite(EOfferType.Product, offer),
      }}*/
    }, offer.id))
  });
};