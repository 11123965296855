import { Typography } from '@mui/material';
import { useAuthUser } from 'features/auth/provider/useAuthUser';
import CompensationCheckbox from 'features/offer/booking/components/compensationCheckbox';
import ServiceCheckbox from 'features/offer/booking/components/serviceCheckbox';
import useTechConfig from 'hooks/useTechConfig';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { EAppFeature } from 'presentation/types';
import { OfferFilterPrice } from '../../../components/filterPrice';
import { CompensationWrapper, PriceWrapper, ServicesWrapper, Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingOfferListFilter = _ref => {
  let {
    isFilterFetching,
    allServices,
    availableServices,
    priceFilter,
    filterCount,
    services,
    availableRange,
    onChangePriceRange,
    onChangeCompensation,
    rzdSocialPackage,
    onSelectService,
    onResetFilters
  } = _ref;
  //todo когда-то надо говно это переделать, чтобы компонент не работал с инфраструктурой
  const {
    hasFeature
  } = useTechConfig();
  const {
    isCorpUser
  } = useAuthUser();
  const isCspEnabled = hasFeature(EAppFeature.Csp) && isCorpUser;
  const {
    minPrice: minValue,
    maxPrice: maxValue
  } = availableRange ?? {};
  const hasPrice = typeof minValue === 'number' && typeof maxValue === 'number';
  const hasServices = allServices && availableServices;
  const servicesIds = availableServices?.map(_ref2 => {
    let {
      id
    } = _ref2;
    return id;
  }) || [];
  const actualValue = {
    minPrice: priceFilter.minPrice ?? minValue ?? null,
    maxPrice: priceFilter.maxPrice ?? maxValue ?? null
  };
  return _jsxs(Wrapper, {
    id: "filter-container",
    children: [isCspEnabled && _jsx(CompensationWrapper, {
      children: _jsx(CompensationCheckbox, {
        value: !!rzdSocialPackage,
        onChange: onChangeCompensation
      })
    }), hasPrice && _jsx(_Fragment, {
      children: _jsxs(PriceWrapper, {
        children: [_jsx(Typography, {
          variant: "subtitle1",
          mb: 2,
          children: "\u0426\u0435\u043D\u0430"
        }), _jsx(OfferFilterPrice, {
          isDisabled: isFilterFetching,
          min: minValue,
          max: maxValue,
          value: actualValue,
          onChange: onChangePriceRange
        })]
      })
    }), hasServices && _jsxs(_Fragment, {
      children: [_jsx(Typography, {
        variant: "subtitle1",
        mb: 2,
        children: "\u0423\u0441\u043B\u0443\u0433\u0438"
      }), _jsx(ServicesWrapper, {
        children: allServices?.map(service => {
          const isChecked = !services || !service.id ? false : services.includes(service.id);
          return _jsx(MPGrid, {
            item: true,
            children: service.id && _jsx(ServiceCheckbox, {
              label: service.name,
              checked: !services ? false : services.includes(service.id)
              // TODO заменить на data-item, когда (и если) станет много услуг
              ,
              onChange: () => onSelectService(service.id),
              disabled: !servicesIds.includes(service.id) && !isChecked
            })
          }, service.id);
        })
      })]
    }), (hasPrice || hasServices) && _jsx(MPButton, {
      fullWidth: true,
      disabled: isFilterFetching || !filterCount,
      variant: "outlined",
      color: "brand",
      size: "small",
      onClick: onResetFilters,
      children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440\u044B"
    })]
  });
};
export default BookingOfferListFilter;