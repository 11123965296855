import { SocialPackageAgreedEventContainer } from './container';
import { socialPackageAgreedEventType } from './types';
export const createEvent = payload => {
  return {
    required: true,
    type: socialPackageAgreedEventType,
    component: SocialPackageAgreedEventContainer,
    mergeWithNext: false,
    mergeWithPrev: true,
    payload
  };
};