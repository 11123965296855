import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPTab } from 'presentation/theme/ui-kit/tab';
import { PartitionIcon } from 'components/common/partitionIcon';
import { SeasonBg, StyledTabs } from './controls';
import SeasonBackgroundImg from 'components/common/seasonBg';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const CatalogTabs = _ref => {
  let {
    tab,
    tabs,
    onChangeTab
  } = _ref;
  return _jsx(MPGrid, {
    container: true,
    spacing: 2,
    alignItems: "center",
    justifyContent: "space-between",
    children: _jsx(MPGrid, {
      item: true,
      zero: 12,
      md: true,
      children: _jsxs(StyledTabs, {
        value: tab,
        textColor: "primary",
        variant: "scrollable",
        scrollButtons: false,
        onChange: (event, tab) => onChangeTab(tab),
        children: [tabs.map(item => _jsx(MPTab, {
          label: item.label,
          value: item.value,
          icon: _jsx(PartitionIcon, {
            type: item.value
          }),
          iconPosition: "start",
          size: 'small'
        }, item.value)), _jsx(SeasonBg, {
          children: _jsx(SeasonBackgroundImg, {})
        })]
      })
    })
  });
};