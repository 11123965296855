import { EUserFavoritesTab } from 'features/user/details/favorites/utils';
import { useState } from 'react';
const useEntityScrollPositions = () => {
  /* scroll positions by entity */
  const [scrollPositions, setScrollPositions] = useState({
    [EUserFavoritesTab.BookingOffers]: null,
    [EUserFavoritesTab.TradeOffers]: null,
    [EUserFavoritesTab.CorpOffers]: null,
    [EUserFavoritesTab.ProductOffers]: null
  });
  const updateScrollPosition = (entity, scrollPosition) => {
    setScrollPositions(prev => ({
      ...prev,
      [entity]: scrollPosition
    }));
  };
  const scrollToPosition = y => {
    window.scrollTo({
      top: y
    });
  };
  return {
    scrollPositions,
    updateScrollPosition,
    scrollToPosition
  };
};
export default useEntityScrollPositions;