import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { toCspRubCurrency } from 'presentationUtils/currency';
import { SocialPackageBanner } from '../../../../socialPackage/components/banners';
import { createEvent as createEventToAgreement } from '../../../../socialPackage/events/toAgreement';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const BookingOfferCspBannerAgreementAdapter = props => {
  const {
    socialPackage
  } = props;
  const {
    publish
  } = useBusinessEventBus();
  return _jsxs(SocialPackageBanner, {
    variant: 'primary',
    actionText: 'Подробнее',
    onClick: () => {
      publish(createEventToAgreement({
        socialPackage
      }));
    },
    children: ["\u041A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u0443\u0435\u043C \u0434\u043E ", toCspRubCurrency(socialPackage.maxLimit), " \u043E\u0442 \u0432\u0430\u0448\u0438\u0445 \u0437\u0430\u0442\u0440\u0430\u0442 \u043D\u0430 \u043E\u0437\u0434\u043E\u0440\u043E\u0432\u043B\u0435\u043D\u0438\u0435 \u0438 \u043E\u0442\u0434\u044B\u0445"]
  });
};