import SocialPackageToConfirmationContent from '../../../components/content/disagreed';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SocialPackageToConfirmationContentAdapter = _ref => {
  let {
    payload
  } = _ref;
  const {
    socialPackage: {
      limitToSelfRest,
      limitToFamilyRest,
      endDate
    }
  } = payload;
  return _jsx(SocialPackageToConfirmationContent, {
    endDate: endDate,
    limitToSelfRest: limitToSelfRest,
    limitToFamilyRest: limitToFamilyRest
  });
};