import { Typography, useMediaQuery } from '@mui/material';
import AddressCitySingleSelector from 'presentation/components/common/address/singleSelector/city';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useCallback, useState } from 'react';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { AddressHelper } from 'utils/address';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CitySelectDialog = props => {
  const {
    visible,
    onChangeCity,
    onClose
  } = props;
  const [localCity, setLocalCity] = useState(null);
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const onChangeLocalCity = useCallback(address => {
    if (!visible) return;
    if (!address) {
      setLocalCity(null);
    } else {
      setLocalCity(address);
      if (isSmDown) {
        onChangeCity(address);
        onClose();
      }
    }
  }, [visible, setLocalCity, isSmDown, onChangeCity, onClose]);
  const onApply = useCallback(() => {
    onChangeCity(localCity ?? {});
    onClose();
  }, [localCity, onChangeCity, onClose]);
  const getOptionLabel = address => new AddressHelper(address).getLocalitySimpleName();
  return _jsx(MPConfirmDialog, {
    title: "\u0412\u044B\u0431\u043E\u0440 \u0433\u043E\u0440\u043E\u0434\u0430",
    open: visible,
    maxWidth: "xs",
    buttons: _jsxs(ConfirmGroupButtons, {
      children: [_jsx(MPButton, {
        fullWidth: true,
        disabled: !localCity,
        color: "brand",
        size: "large",
        onClick: onApply,
        children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C"
      }), _jsx(MPButton, {
        color: "brand",
        variant: "text",
        fullWidth: true,
        size: "large",
        onClick: onClose,
        children: "\u041E\u0442\u043C\u0435\u043D\u0430"
      })]
    }),
    onClose: onClose,
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 2,
      children: [_jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(Typography, {
          variant: "body2",
          children: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435, \u0433\u0434\u0435 \u0432\u044B \u043D\u0430\u0445\u043E\u0434\u0438\u0442\u0435\u0441\u044C \u0434\u043B\u044F \u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E\u0433\u043E \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0439"
        })
      }), _jsx(MPGrid, {
        item: true,
        zero: 12,
        children: _jsx(AddressCitySingleSelector, {
          value: localCity,
          label: "\u0413\u043E\u0440\u043E\u0434",
          getOptionLabel: getOptionLabel,
          inputProps: {
            autoFocus: true
          },
          onChange: onChangeLocalCity
        })
      })]
    })
  });
};
export default CitySelectDialog;