import { Typography } from '@mui/material';
import { toCspRubCurrency } from 'presentationUtils/currency';
import { MPGrid } from 'theme/ui-kit/grid';
import { Wrapper } from './controls';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CompensationRest = _ref => {
  let {
    limitToSelfRest,
    limitToFamilyRest
  } = _ref;
  return _jsx(Wrapper, {
    children: _jsxs(MPGrid, {
      container: true,
      spacing: 2,
      children: [!!limitToSelfRest && _jsxs(MPGrid, {
        item: true,
        zero: 6,
        children: [_jsx(Typography, {
          variant: "body2",
          children: "\u0414\u043B\u044F \u0432\u0430\u0441"
        }), _jsx(Typography, {
          variant: "h2",
          children: toCspRubCurrency(limitToSelfRest)
        })]
      }), !!limitToFamilyRest && _jsxs(MPGrid, {
        item: true,
        zero: 6,
        children: [_jsx(Typography, {
          variant: "body2",
          children: "\u0414\u043B\u044F \u0447\u043B\u0435\u043D\u043E\u0432 \u0441\u0435\u043C\u044C\u0438"
        }), _jsx(Typography, {
          variant: "h2",
          children: toCspRubCurrency(limitToFamilyRest)
        })]
      })]
    })
  });
};
export default CompensationRest;