const link = () => ({
  MuiLink: {
    defaultProps: {
      underline: 'hover'
    },
    styleOverrides: {
      root: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center'
      }
    }
  }
});
export default link;