import { ESocialPackageStatus } from 'domain/model';
import { EBalanceType, EBTTransactionType, EUserGender, EUserRole } from 'domain/model/enums';
import { EDateFormat } from 'domain/model/formats';
import { currencySymbols } from 'locale';
import moment from 'moment-timezone';
import { pluralize } from 'presentation/utils';
import { toCspRubCurrency, toCurrency } from 'presentation/utils/currency';
import rootRouting from '../../../routing';
import { EAppFeature } from '../../types';
import { utcToLocalTimeZone } from '../../utils/date';
import { getUserProfileRoute } from './entry';
export const getUserGenderName = gender => {
  switch (gender) {
    case EUserGender.Male:
      return 'Мужской';
    case EUserGender.Female:
      return 'Женский';
  }
};
export const getAllowedRoles = realmRoles => {
  return realmRoles.filter(existed => Object.values(EUserRole).some(localRole => localRole === existed));
};
export const getCustomerAllowedRoles = userRoles => {
  return userRoles.filter(userRole => [EUserRole.Customer, EUserRole.Corp].includes(userRole));
};
export const getUserDefaultRoute = (hasRole, hasFeature) => {
  // Открываем страницу товаров для всех
  if (hasFeature(EAppFeature.Product)) {
    return rootRouting.product;
  }

  // Открываем страницу корпоративных предложений
  if (hasRole(EUserRole.Corp)) {
    return rootRouting.corpOffer;
  }

  // Открываем страницу торговых предложений
  if (hasFeature(EAppFeature.Trade)) {
    return rootRouting.tradeOffer;
  }

  // Если ничего нет то страницу профиля
  return getUserProfileRoute();
};
export const getUserBalanceExpireMessage = (value, expireDate, balance) => {
  let typedValue = '';
  switch (balance.type) {
    case EBalanceType.Bonuses:
      typedValue = pluralize(value, ['бонусный балл', 'бонусных балла', 'бонусных баллов']);
      break;
    case EBalanceType.ASP:
    case EBalanceType.CSP:
      typedValue = currencySymbols.RU;
      break;
  }
  const dateMoment = utcToLocalTimeZone(expireDate);
  let date = null;
  switch (balance.type) {
    case EBalanceType.Bonuses:
    case EBalanceType.ASP:
    case EBalanceType.CSP:
      date = dateMoment.year() === new Date().getFullYear() // если в текущем году - не отображаем год
      ? dateMoment.format(EDateFormat.HumanShort) : dateMoment.format(EDateFormat.Human);
      break;
  }
  switch (balance.type) {
    case EBalanceType.CSP:
      if (balance.source.status === ESocialPackageStatus.Upcoming) {
        return ['Будет активен с ', date].filter(item => !!item).join(' ');
      }
      return ['До', date].filter(item => !!item).join(' ');
    default:
      return [date, 'сгорит', toCurrency(value), typedValue].filter(item => !!item).join(' ');
  }
};
export const getLabelUserBTTransactionLabel = (type, isExpiration) => {
  if (EBTTransactionType.Debit && isExpiration) {
    return 'Сгорание';
  }
  switch (type) {
    case EBTTransactionType.Credit:
      return 'Начисление';
    case EBTTransactionType.Debit:
      return 'Списание';
  }
};
export function formatDateBenefitTransactions(date) {
  if (!date) {
    return null;
  }
  const momentDate = moment(date);
  const currentYear = moment().year();

  // Если год текущий, выводим только день и месяц
  if (momentDate.year() === currentYear) {
    return momentDate.format(EDateFormat.HumanShort);
  }

  // Если год предыдущий или следующий, выводим день, месяц и год
  return momentDate.format(EDateFormat.Human);
}
export function getAmountByTypeTransaction(type, amount) {
  switch (type) {
    case EBTTransactionType.Credit:
      return `+${amount}`;
    case EBTTransactionType.Debit:
      return `-${amount}`;
  }
}
export function getEqualDates(onDate, twoDate) {
  const date1 = moment(onDate);
  const date2 = moment(twoDate);
  return date1.isSame(date2, 'day');
}
export const getCspRubCurrencyLabel = n => {
  return 'до ' + toCspRubCurrency(n);
};