import { EOfferStatus } from 'domain/model/enums';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import InActive from '../components/inActive';
import BookingOfferBreadcrumbAdapter from './adapters/breadcrumb';
import BookingOfferCarouselAdapter from './adapters/carousel';
import BookingOfferCloseAdapter from './adapters/close';
import BookingOfferDetailsFavoriteAdapter from './adapters/favorite';
import BookingOfferDetailsLoaderAdapter from './adapters/loader';
import BookingOfferMapAdapter from './adapters/map';
import BookingOfferDetailsModalsAdapter from './adapters/modals';
import BookingOfferPartnerInfoAdapter from './adapters/partnerInfo';
import BookingOfferSidebarAdapter from './adapters/sidebar';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import BookingOfferDetailStub from './adapters/stub';
import BookingOfferDetailsTabsAdapter from './adapters/tabs';
import BookingOfferDetailsTitleAdapter from './adapters/title';
import { BookingOfferDetailContext } from './context';
import useBookingOfferDetails from './hooks/useBookingOfferDetails';
import useBookingOfferDetailsData from './hooks/useBookingOfferDetailsData';
import BookingOfferDetailsLayout from './layout';
import BookingOfferSimpleLayout from './layout/simple';
import BookingOfferBannerCspAdapter from './adapters/bannerCsp';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Close = _jsx(BookingOfferCloseAdapter, {});
const Modals = _jsx(BookingOfferDetailsModalsAdapter, {});
const BookingOfferDetailsContainer = _ref => {
  let {
    id,
    tab,
    guid,
    from
  } = _ref;
  const handlers = useBookingOfferDetails(id, from);
  const {
    bookingOffer
  } = useBookingOfferDetailsData(id);
  const isActiveStatus = bookingOffer?.status === EOfferStatus.Active;
  return _jsx(BookingOfferDetailContext.Provider, {
    value: handlers,
    children: _jsx(RenderManagerErrorProvider, {
      children: _jsx(CommonRenderManager, {
        loader: _jsx(BookingOfferDetailsLoaderAdapter, {
          id: id
        }),
        stub: BookingOfferDetailStub,
        children: isActiveStatus ? _jsxs(_Fragment, {
          children: [_jsx(BookingOfferDetailsLayout, {
            id: id,
            favorite: _jsx(BookingOfferDetailsFavoriteAdapter, {
              id: id,
              guid: guid
            }),
            banner: _jsx(BookingOfferBannerCspAdapter, {
              id: id
            }),
            description: _jsx(BookingOfferMapAdapter, {
              id: id
            }),
            sidebar: _jsx(BookingOfferSidebarAdapter, {
              id: id
            }),
            smartphoneFixedBar: _jsx(SmartPhoneFixedBarAdapter, {
              guid: guid,
              id: id
            }),
            tabs: _jsx(BookingOfferDetailsTabsAdapter, {
              guid: guid,
              tab: tab,
              id: id
            }),
            partnerInfo: _jsx(BookingOfferPartnerInfoAdapter, {
              id: id
            }),
            title: _jsx(BookingOfferDetailsTitleAdapter, {
              id: id
            }),
            breadcrumb: _jsx(BookingOfferBreadcrumbAdapter, {
              id: id
            }),
            image: _jsx(BookingOfferCarouselAdapter, {
              id: id
            }),
            close: Close
          }), Modals]
        }) : _jsx(BookingOfferSimpleLayout, {
          title: bookingOffer?.name ?? '',
          close: Close,
          id: id,
          children: InActive
        })
      })
    })
  });
};
export default BookingOfferDetailsContainer;