import { Fade, useMediaQuery, useTheme } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { EContentbarGridAreas, EInnerGridAreas } from 'presentation/layouts/bookingDetail/types';
import BookingDetailLayout from '../../../../../layouts/bookingDetail';
import useBookingOfferDetailsLoadingStatus from '../hooks/useBookingOfferDetailsLoadingStatus';
import { FitContainer } from './controls';
import useVisibleSectionLinks from '../../../../sectionLinks/hooks/useVisibleSectionLinks';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const layoutFixedAreas = [EContentbarGridAreas.LargeDesktopTabs, EContentbarGridAreas.Description, EInnerGridAreas.Bottombar];
const BookingOfferDetailsLayout = _ref => {
  let {
    id,
    image,
    toolbar,
    breadcrumb,
    sidebar,
    tabs,
    partnerInfo,
    title,
    description,
    close,
    smartphoneFixedBar,
    favorite,
    banner
  } = _ref;
  const isBookingOfferLoading = useBookingOfferDetailsLoadingStatus({
    id
  });
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  useVisibleSectionLinks(downMd);
  return _jsxs(_Fragment, {
    children: [_jsx(ScreenNavWrapper, {
      children: toolbar
    }), _jsx(Fade, {
      in: true,
      children: _jsx(DefaultContentWrapper, {
        children: _jsx(FitContainer, {
          children: isBookingOfferLoading ? null : _jsx(BookingDetailLayout, {
            breadcrumb: breadcrumb,
            description: description,
            title: title,
            sidebar: sidebar,
            partnerInfo: partnerInfo,
            images: image,
            favorite: favorite,
            smartphoneFixedBar: smartphoneFixedBar,
            content: tabs,
            close: close,
            fixedPositionsAreas: layoutFixedAreas,
            banner: banner
          })
        })
      })
    })]
  });
};
export default BookingOfferDetailsLayout;