import { MPButton } from 'theme/ui-kit/button';
import ConfirmGroupButtons from 'theme/ui-kit/button/confirmGroup';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SocialPackageHowToUseActionsAdapter = _ref => {
  let {
    onNext
  } = _ref;
  return _jsx(ConfirmGroupButtons, {
    children: _jsx(MPButton, {
      color: "brand",
      fullWidth: true,
      size: 'large',
      onClick: onNext,
      children: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
    })
  });
};