import { useBusinessEventBus } from '@privilege-frontend/eventBus';
import { DialogueEventContentBuilderLayout } from 'features/eventsFlow/dialogue';
import { createEvent as createEventAgreed } from '../agreed';
import { createEvent as createEventRefuse } from '../refuse';
import { SocialPackageToAgreementActionsAdapter, SocialPackageToAgreementContentAdapter, SocialPackageToAgreementTitleAdapter } from './adapters';
import { socialPackageToAgreementEventType } from './types';

/**
 * состояние КСП
 *
 * есть признак КСП у пользователя
 * еще не согласился
 * готов к активации {@link ESocialPackageStatus.Ready}
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SocialPackageToAgreementEventContainer = props => {
  const {
    payload: {
      socialPackage
    },
    onNext
  } = props;
  const {
    publish
  } = useBusinessEventBus();
  const onRefuse = () => {
    publish({
      ...createEventRefuse({
        socialPackage
      }),
      hardInsertAfterType: socialPackageToAgreementEventType
    });
    onNext();
  };
  const onAgreed = () => {
    publish({
      ...createEventAgreed({
        socialPackage
      }),
      hardInsertAfterType: socialPackageToAgreementEventType
    });
    onNext();
  };
  return _jsx(DialogueEventContentBuilderLayout, {
    title: _jsx(SocialPackageToAgreementTitleAdapter, {
      ...props
    }),
    actions: _jsx(SocialPackageToAgreementActionsAdapter, {
      ...props,
      onRefuse: onRefuse,
      onAgreed: onAgreed
    }),
    titleAsContent: true,
    children: _jsx(SocialPackageToAgreementContentAdapter, {
      ...props
    })
  });
};