import { Dialog, useMediaQuery } from '@mui/material';
import { CloseIcon } from 'presentation/media/icons';
import { MPDialogContentBuilder } from 'theme/ui-kit/dialog/common/contentBuilder';
import { MPCommonDialogContext } from 'theme/ui-kit/dialog/common/context';
import { CloseButton } from './controls';
import { MPDialogBackButton } from './dialogBackButton';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const MPDialog = props => {
  const {
    children,
    fullWidth = true,
    maxWidth = 'md',
    closeToBackButtonInFullScreen = true,
    closeButtonVisible = true,
    closeButton,
    backButtonLabel,
    title,
    fullScreenBreakpoint,
    externalBuilder,
    ...others
  } = props;
  const fullScreenBreakpointDefault = others.fullScreen ? 10000 : 0;
  const downSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const fullScreen = useMediaQuery(theme =>
  //делаем +1, чтобы захватить нижнюю границу брекпойнта
  theme.breakpoints.down(fullScreenBreakpoint ? theme.breakpoints.values[fullScreenBreakpoint] + 1 : fullScreenBreakpointDefault));
  const isBackButtonVisible = closeButtonVisible && others.onClose && fullScreen && closeToBackButtonInFullScreen;
  const isCloseIconVisible = closeButtonVisible && others.onClose && (!fullScreen || !closeToBackButtonInFullScreen);
  return _jsxs(Dialog, {
    fullWidth: fullWidth,
    maxWidth: maxWidth,
    scroll: downSm ? 'paper' : 'body',
    PaperProps: {
      elevation: 0,
      variant: 'outlined'
    },
    ...others,
    fullScreen: others.fullScreen || fullScreen,
    children: [isBackButtonVisible && _jsx(MPDialogBackButton, {
      label: backButtonLabel,
      onClick: event => others.onClose?.(event, 'escapeKeyDown')
    }), isCloseIconVisible && _jsx(_Fragment, {
      children: closeButton ? closeButton : _jsx(CloseButton, {
        onClick: event => others.onClose?.(event, 'custom'),
        children: _jsx(CloseIcon, {
          fontSize: "large"
        })
      })
    }), _jsx(MPCommonDialogContext.Provider, {
      value: {
        title,
        isBackButtonVisible,
        wrapper: others.wrapper,
        fullScreen,
        onClose: others.onClose
      },
      children: externalBuilder ? children : _jsx(MPDialogContentBuilder, {
        ...props
      })
    })]
  });
};